import { Button } from "@tremor/react";
import { LogoFull } from "components/images/Logo/Full";
import { useAuth } from "context/auth";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import api from "services/api";

const Login: React.FC = () => {
  const { setToken } = useAuth();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // @ts-ignore
    const email = e.target.elements?.email?.value;
    // @ts-ignore
    const password = e.target.elements?.password?.value;

    handleLogin(email, password);
  }

  async function handleLogin(email: string, password: string): Promise<void> {
    setLoading(true);

    try {
      const { data } = await api.post("/auth/login", { email, password });

      if (!data.success) throw new Error();

      setToken(data.token);
      history.push("/dashboard");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
      <div className="flex justify-center sm:mx-auto sm:w-full sm:max-w-sm">
        <LogoFull className="h-11" />
      </div>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email address
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                required
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 invalid:border-red-500 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Password
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <Button size="xs" className="w-full" loading={loading}>
              Entrar
            </Button>
            {/* <p className="mt-10 text-center text-sm text-gray-500">
              Ainda não tem uma conta?{" "}
              <Link
                to="/registration"
                className="font-semibold leading-6 text-primary hover:underline"
              >
                Criar uma agora
              </Link>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
