import React from "react";
import { bindClassNames } from "utils/tailwind";

type TProps = {
  title: string | React.ReactNode;
  extra?: React.ReactNode[];
  className?: string;
};

const Divider: React.FC<TProps> = ({ title, extra, className }: TProps) => {
  return (
    <div className={bindClassNames(className, "mb-8 flex border-b")}>
      <div className="mb-2.5 flex w-full items-center justify-between">
        <span className="text-base font-medium text-text">{title}</span>
        <div>{extra && extra.map((item) => item)}</div>
      </div>
    </div>
  );
};

export default Divider;
