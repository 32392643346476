import React, { useContext, useEffect, useMemo, useState } from "react";
import api from "services/api";

type IAuthContext = {
  isAuthenticated: boolean;
  token: Token;
  setToken(token: Token): void;
};
type Token = string | null | undefined;
export type TTokenData = {
  id_user: string;
  name: string;
};

const AuthContext = React.createContext<IAuthContext>({} as IAuthContext);
export const TOKEN_KEY = "@my-finance-token";

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token_, setToken_] = useState<Token>(localStorage.getItem(TOKEN_KEY));

  const setToken = (token: Token) => {
    setToken_(token);
  };

  useEffect(() => {
    console.log("Token updated", token_);

    if (token_) {
      api.defaults.headers.common["Authorization"] = "Bearer " + token_;
      localStorage.setItem(TOKEN_KEY, token_);
    } else {
      delete api.defaults.headers.common["Authorization"];
      localStorage.removeItem(TOKEN_KEY);
    }
  }, [token_]);

  const isAuthenticated = useMemo(() => {
    return !!token_;
  }, [token_]);

  return (
    <AuthContext.Provider value={{ setToken, token: token_, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  return useContext(AuthContext);
};

export default AuthProvider;
