import Divider from "components/Divider";
import Table from "components/Table";
import Container from "components/layout/Container";
import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import service, { TUserStockInfo } from "services/service";
import {
  formatToDate,
  formatToMoney,
  formatToNumber,
  formatToPercentage,
} from "utils/formatters";
import { toast } from "react-toastify";
import Translate from "components/Translate";
import { BarChart } from "@tremor/react";

const StockInfo: React.FC = () => {
  const { id_user_stock } = useParams<any>();
  // const { goBack } = useHistory();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<TUserStockInfo>();

  useEffect(() => {
    fetchUserStock();
  }, []);

  async function fetchUserStock() {
    setLoading(true);

    try {
      const response = await service.userStockInfo(id_user_stock);

      setData(response);
    } catch (err) {
      toast.error("Tivemos um problema ao carregar a página, tente novamente!");
      // goBack();
    } finally {
      setLoading(false);
    }
  }

  const summary = useMemo(() => {
    const total_dividends =
      data?.dividends?.reduce(
        (prev, curr) => prev + curr.amount * curr.quantity,
        0,
      ) || 0;

    const yoc = total_dividends
      ? formatToPercentage(
          (total_dividends / (data?.info?.average_price || 0)) * 100,
        )
      : "-";

    return [
      { title: "P/M", value: formatToMoney(data?.info?.average_price) },
      {
        title: "Preço Atual",
        value: formatToMoney(data?.stock?.current_market_price),
      },
      {
        title: "Cotas",
        value: formatToNumber(data?.info?.quantity),
      },
      {
        title: "Valor Investido",
        value: formatToMoney(data?.info?.invested_amount),
      },
      { title: "YOC", value: yoc },
    ];
  }, [data]);

  const tableDividends = useMemo(() => {
    return (
      data?.dividends?.map((item) => ({
        unit_amount: formatToMoney(item.amount),
        unit: formatToNumber(item.quantity),
        type: <Translate key={1} path={`global.${item.type}`} />,
        total_amount: formatToMoney(item.amount * item.quantity),
        yoc: formatToPercentage(
          ((item.amount * item.quantity) / data.info.average_price) * 100,
        ),
        date: formatToDate(item.date, "month_year"),
      })) || []
    );
  }, [data]);
  const chartDividends = useMemo(() => {
    return (
      data?.dividends
        ?.map((item) => ({
          date: formatToDate(item.date, "month_year"),
          "Valor Total": item.amount * item.quantity,
        }))
        ?.reverse() || []
    );
  }, [data]);
  const tableMovements = useMemo(() => {
    return (
      data?.movements?.map((item) => ({
        type: <Translate path={`global.${item.type}`} />,
        quantity: formatToNumber(item.quantity),
        amount: formatToMoney(item.amount),
        total: formatToMoney(item.amount * item.quantity),
        date: formatToDate(item.date, "date"),
      })) || []
    );
  }, [data]);

  return (
    <Container
      title={data?.stock?.symbol}
      subtitle={data?.stock?.long_name}
      breadcrumb={[
        <Translate key={1} path="global.stock" />,
        data?.stock?.type || "",
        data?.stock?.symbol || "",
      ]}
      loading={loading}
    >
      <summary className="flex w-full list-none rounded border border-dashed">
        {summary.map((item) => (
          <div
            key={item.title}
            className="flex w-full flex-col border-r border-dashed p-2.5 text-center last:border-none"
          >
            <span className="text-sm font-normal text-subtitle">
              {item.title}
            </span>
            <span className="text-base font-medium">
              {loading ? <Skeleton /> : item.value}
            </span>
          </div>
        ))}
      </summary>

      <Divider
        title={<Translate key={1} path="global.earnings" />}
        className="mt-12"
      />

      <Table
        columns={[
          { key: "date" },
          {
            title: "Tipo",
            key: "type",
            classNames: "text-center",
          },
          {
            title: "Valor Unit.",
            key: "unit_amount",
            classNames: "text-center",
          },
          {
            title: "Unidades",
            key: "unit",
            classNames: "text-center",
          },
          {
            title: "Valor Total",
            key: "total_amount",
            classNames: "text-center",
          },
          {
            title: "YOC",
            key: "yoc",
            classNames: "text-center",
          },
        ]}
        dataSource={tableDividends}
        loading={loading}
        loadingSkeletonSize="sm"
      />

      <BarChart
        className="mt-6 h-52 w-full"
        data={chartDividends}
        index="date"
        categories={["Valor Total"]}
        colors={["blue"]}
        valueFormatter={formatToMoney}
        showLegend={false}
        showYAxis={false}
        showGridLines={false}
        showAnimation={true}
        animationDuration={500}
      />

      <Divider
        title={<Translate key={1} path="global.movements" />}
        className="mt-12"
      />

      <Table
        columns={[
          { title: "Operação", key: "type", classNames: "text-center" },
          { title: "Quantidade", key: "quantity", classNames: "text-center" },
          { title: "Valor", key: "amount", classNames: "text-center" },
          { title: "Total", key: "total", classNames: "text-center" },
          { key: "date", classNames: "text-center" },
        ]}
        dataSource={tableMovements}
        loading={loading}
        loadingSkeletonSize="sm"
      />
    </Container>
  );
};

export default StockInfo;
