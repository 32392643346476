import React from "react";
import { BadgeDelta } from "@tremor/react";
import { formatToMoney } from "./formatters";
import CountUp from "react-countup";

export function getBadge(first: number, second: number) {
  const diff_percent = (first * 100) / second - 100;
  const diff_amount = first - second;

  let type:
    | "increase"
    | "moderateIncrease"
    | "decrease"
    | "moderateDecrease"
    | "unchanged";

  if (diff_percent < -25) type = "decrease";
  else if (diff_percent < 0) type = "moderateDecrease";
  else if (diff_percent > 0 && diff_percent < 25) type = "moderateIncrease";
  else if (diff_percent >= 25) type = "increase";
  else type = "unchanged";

  return (
    <BadgeDelta deltaType={type} tooltip={formatToMoney(diff_amount)}>
      <CountUp
        end={diff_percent}
        decimals={2}
        formattingFn={format}
        duration={0.5}
      />
    </BadgeDelta>
  );
}
function format(value: number) {
  return `${value.toFixed(2)}%`;
}
