import React, { useContext, useEffect, useState } from "react";
// import { decode } from "jsonwebtoken";
import { jwtDecode } from "jwt-decode";
import { TTokenData, useAuth } from "./auth";

type IUserContext = {
  user: TUser | undefined;
};

const UserContext = React.createContext<IUserContext>({} as IUserContext);

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  const { token } = useAuth();

  const [user, setUser] = useState<TUser>();

  useEffect(() => {
    if (token) {
      const decoded = jwtDecode(token) as TTokenData | undefined;

      if (decoded) {
        setUser({
          id_user: decoded.id_user,
          name: decoded.name,
        });
      }
    }
  }, [token]);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export const useUser = (): IUserContext => {
  return useContext(UserContext);
};

export default UserProvider;

export type TUser = {
  id_user: string;
  name: string;
};
