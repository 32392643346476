import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import React, { useMemo } from "react";

import { useAuth } from "context/auth";

import LayoutMainNew from "../layout/MainNew";
import Registration from "pages/Registration";
import StockInfo from "../pages/Stocks/Info";
import Movements from "../pages/Movements";
import Dashboard from "../pages/Dashboard";
import Stocks from "../pages/Stocks";
import Login from "../pages/Login";

const Routes: React.FC = () => {
  const { isAuthenticated } = useAuth();

  const routes = useMemo(() => {
    if (!isAuthenticated) {
      return (
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route path="/login" component={Login} />
          <Route path="/registration" component={Registration} />
          <Route path="*">
            <Redirect to="/login" />
          </Route>
        </Switch>
      );
    }

    return (
      <LayoutMainNew>
        <Switch>
          <Route exact path="/">
            <Redirect to="/dashboard" />
          </Route>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/stocks" component={Stocks} />
          <Route path="/stock/:id_user_stock" component={StockInfo} />
          <Route path="/statements" component={Movements} />
        </Switch>
      </LayoutMainNew>
    );
  }, [isAuthenticated]);

  return (
    <BrowserRouter>
      <div>{routes}</div>
    </BrowserRouter>
  );
};

export default Routes;
