import {
  Table as TableTremor,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from "@tremor/react";
import React, { memo, useMemo } from "react";
import { bindClassNames } from "utils/tailwind";
import Skeleton from "react-loading-skeleton";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  InboxIcon,
} from "@heroicons/react/outline";
import Translate from "components/Translate";

type TProps = {
  columns: Column[];
  dataSource: any[];
  loading?: boolean;
  loadingSkeletonSize?: Size;
  pagination?: Pagination;
};
type Size = "sm" | "md" | "lg";
type Column = {
  title?: string;
  key: string;
  classNames?: string;
};
type Pagination = {
  total: number;
  page: number;
  pageSize: number;
  onChange: (page: number, pageSize: number) => void;
};

const Table: React.FC<TProps> = ({
  columns,
  dataSource,
  loading,
  loadingSkeletonSize,
  pagination,
}: TProps) => {
  const data = useMemo(() => {
    if (loading) {
      return Array.from({ length: 5 }).map(() => "");
    }

    return dataSource;
  }, [dataSource, loading]);

  function getSkeletonSize(size: Size = "md"): number {
    if (size === "sm") return 14;
    if (size === "lg") return 25;
    return 25;
  }

  const extra = useMemo(() => {
    if (!data.length && !loading) {
      return (
        <div className="flex h-52 w-full flex-col items-center justify-center text-border">
          <InboxIcon className="w-10" />
          <span>No data</span>
        </div>
      );
    }

    if (pagination) {
      const pag_start = (pagination.page - 1) * pagination.pageSize + 1;
      const pag_end = pagination.page * pagination.pageSize;

      return (
        <div className="mt-3 flex items-center justify-between border-t pt-2 text-sm">
          <button
            disabled={pagination.page === 1}
            className="disabled:opacity-25"
            onClick={() =>
              pagination.onChange(pagination.page - 1, pagination.pageSize)
            }
          >
            <ChevronLeftIcon className="w-5" />
          </button>
          <span>
            <Translate
              path={`components.table.pagination`}
              data={{
                start: String(pag_start),
                end: String(
                  pag_end > pagination.total ? pagination.total : pag_end,
                ),
                total: pagination.total,
              }}
              component={{
                span: <span className="font-medium text-primary" />,
              }}
            />
          </span>
          <button
            disabled={pagination.page * pagination.pageSize >= pagination.total}
            className="disabled:opacity-25"
            onClick={() =>
              pagination.onChange(pagination.page + 1, pagination.pageSize)
            }
          >
            <ChevronRightIcon className="w-5" />
          </button>
        </div>
      );
    }
  }, [dataSource, loading, pagination]);

  return (
    <>
      <TableTremor className="mt-5">
        <TableHead>
          <TableRow>
            {columns.map((column) => {
              return (
                <TableHeaderCell
                  className={bindClassNames(column.classNames, "p-2")}
                  key={column.key}
                >
                  {column.title}
                </TableHeaderCell>
              );
            })}
          </TableRow>
        </TableHead>
        {data.length || loading ? (
          <TableBody>
            {data?.map((item) => (
              <TableRow key={item.id_user_stock}>
                {columns.map((column) => {
                  return (
                    <TableCell
                      key={column.key}
                      className={bindClassNames(column.classNames, "p-2")}
                    >
                      {loading ? (
                        <Skeleton
                          height={getSkeletonSize(loadingSkeletonSize)}
                        />
                      ) : (
                        item[column.key]
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        ) : null}
      </TableTremor>

      {extra}
    </>
  );
};

export default memo(Table);
