export default {
  translations: {
    global: {
      buy: "Compra",
      sell: "Venda",
      dividend: "Dividendo",
      dividends: "Dividendos",
      stock: "Título",
      stocks: "Títulos",
      jcp: "JCP",
      movements: "Movimentações",
      earnings: "Proventos",
      dashboard: "Dashboard",
      reports: "Relatórios",
      profile: "Perfil",
      logout: "Sair",
    },
    components: {
      table: {
        pagination: `Exibindo <span>{{start}}</span>-<span>{{end}}</span> de <span>{{total}}</span> resultados`,
      },
    },
  },
};
