import React, { useMemo } from "react";
import { AreaChart } from "@tremor/react";
import Container from "components/layout/Container";
import { useUser } from "context/user";
import Divider from "components/Divider";
import DashboardMetrics from "components/layout/Dashboard/Metrics";
import { formatToDate, formatToMoney } from "utils/formatters";
import service from "services/service";
import { useQuery } from "react-query";

const Dashboard: React.FC = () => {
  const { user } = useUser();

  const { data, isLoading } = useQuery({
    queryKey: ["dashboard", user?.id_user],
    queryFn: () => service.userDashboard(),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  const title = useMemo(() => {
    return (
      <>
        Boa tarde, <span className="text-primary">{user?.name}</span>!
      </>
    );
  }, [user]);

  const historyData = useMemo(() => {
    return (
      data?.summary?.history?.map((item) => ({
        Total: item.amount,
        date: formatToDate(item.date, "date"),
      })) || []
    );
  }, [data]);

  return (
    <Container title={title} subtitle="Seja bem vindo" loading={isLoading}>
      <Divider
        title={formatToDate(new Date(), "month_year")}
        // extra={[
        //   <DateRangePicker
        //     key={1}
        //     className="mx-auto max-w-sm"
        //     locale={ptBR}
        //   />,
        // ]}
      />

      <div className="flex w-full gap-4">
        <div className="w-full">
          <DashboardMetrics
            title="Total"
            amount={data?.summary?.total_amount || 0}
            prev_amount={data?.summary?.total_amount_prev}
            loading={isLoading}
          />

          <AreaChart
            className="mt-6 h-28"
            data={historyData}
            index="date"
            categories={["Total"]}
            colors={["blue"]}
            valueFormatter={formatToMoney}
            // yAxisWidth={40}
            showGridLines={false}
            showXAxis={false}
            // startEndOnly={true}
            showYAxis={false}
            showLegend={false}
            showAnimation
            animationDuration={500}
          />
        </div>
        <div className="flex w-full flex-col justify-between">
          {/* <DashboardMetrics
            title="Ações"
            amount={data?.summary?.stock_amount || 0}
            prev_amount={data?.summary?.stock_amount_prev}
            loading={isLoading}
          />
          <DashboardMetrics
            title="FIIs"
            amount={data?.summary?.stock_amount || 0}
            prev_amount={data?.summary?.stock_amount_prev}
            loading={isLoading}
          /> */}
          <DashboardMetrics
            title="Dividendos"
            amount={data?.summary?.dividends_amount || 0}
            prev_amount={data?.summary?.dividends_amount_prev}
            loading={isLoading}
          />
        </div>
      </div>
    </Container>
  );
};

export default Dashboard;
