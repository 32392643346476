import { Button } from "@tremor/react";
import { LogoFull } from "components/images/Logo/Full";
import { LogoSmall } from "components/images/Logo/Small";
import { useAuth } from "context/auth";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import api from "services/api";

type TCreateAccount = {
  name: string;
  document: string;
  email: string;
  password: string;
  confirm_password: string;
};

const Registration: React.FC = () => {
  const { setToken } = useAuth();

  const history = useHistory();

  const [loading, setLoading] = useState(false);

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    // @ts-ignore
    const name = e.target.elements?.name?.value;
    // @ts-ignore
    const document = e.target.elements?.document?.value;
    // @ts-ignore
    const email = e.target.elements?.email?.value;
    // @ts-ignore
    const password = e.target.elements?.password?.value;
    // @ts-ignore
    const confirm_password = e.target.elements?.confirm_password?.value;

    handleCreateAccount({
      email,
      password,
      name,
      document,
      confirm_password,
    });
  }

  async function handleCreateAccount(data: TCreateAccount): Promise<void> {
    // setLoading(true);

    console.log("data", data);
  }

  return (
    <div className="flex min-h-full flex-col justify-center px-6 py-12 sm:mx-auto sm:w-full sm:max-w-sm lg:px-8">
      <div className="flex justify-center">
        <LogoSmall className="h-11" />
      </div>

      {/* <h1 className="mt-5">Abrir minha conta</h1> */}
      <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
        Abrir minha conta
      </h2>

      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-3" onSubmit={handleSubmit}>
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Nome
            </label>
            <div className="mt-2">
              <input
                id="name"
                name="name"
                type="name"
                autoComplete="name"
                placeholder="Eduardo Antonio"
                required
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="document"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Documento
            </label>
            <div className="mt-2">
              <input
                id="document"
                name="document"
                type="document"
                autoComplete="document"
                placeholder="005.542.138-50"
                required
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Email
            </label>
            <div className="mt-2">
              <input
                id="email"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="eduardo-damota84@gmail.com"
                required
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Senha
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="new-password"
                required
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <div className="flex items-center justify-between">
              <label
                htmlFor="password"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Confirmação de Senha
              </label>
            </div>
            <div className="mt-2">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div>
            <Button size="xs" className="mt-10 w-full" loading={loading}>
              Abrir minha conta
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Registration;
