import { ChevronRightIcon } from "@heroicons/react/outline";
import Table from "components/Table";
import Container from "components/layout/Container";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import api from "services/api";
import { getBadge } from "utils/badge";
import {
  formatToMoney,
  formatToNumber,
  formatToPercentage,
} from "utils/formatters";

type IData = {
  id_user_stock: string;
  id_user: string;
  quantity: number;
  invested_amount: number;
  average_price: number;
  deleted_at: null;
  short_name: string;
  long_name: string;
  symbol: string;
  logo_url: string;
  current_market_price: number;
  type: string;
  category: string;
};
type StocksResponse = {
  page: number;
  page_size: number;
  total: number;
  data: IData[];
};

const Stocks: React.FC = () => {
  const default_filter = {
    page: 1,
    page_size: 20,
  };

  const [filter, setFilter] = useState(default_filter);
  const [data, setData] = useState<StocksResponse>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    fetchUserStock();
  }, [filter]);

  async function fetchUserStock() {
    setLoading(true);

    try {
      const { data: response } = await api.get("/user/stock", {
        params: filter,
      });

      setData(response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const tableData = useMemo(() => {
    const total_quantity =
      data?.data?.reduce((prev, curr) => (prev += curr.quantity), 0) || 0;

    return data?.data?.map((item) => ({
      logo: <img src={item.logo_url} className="h-5 w-5 rounded" />,
      symbol: (
        <>
          {item.symbol} <span className="ml-1 text-slate-400">{item.type}</span>
        </>
      ),
      category: item.category,
      percentage: formatToPercentage((item.quantity / total_quantity) * 100),
      quantity: formatToNumber(item.quantity),
      average_price: formatToMoney(item.average_price),
      // invested_amount: formatToMoney(item.invested_amount),
      // current_market_price: formatToMoney(
      //   item.current_market_price * item.quantity,
      // ),
      final: (
        <>
          {getBadge(
            item.current_market_price * item.quantity,
            item.invested_amount,
          )}
          <Link className="text-blue-500" to={`/stock/${item.id_user_stock}`}>
            <ChevronRightIcon className="w-4" />
          </Link>
        </>
      ),
    }));
  }, [data]);

  return (
    <Container title="Meus Títulos">
      <Table
        columns={[
          { key: "logo" },
          { key: "symbol" },
          { title: "%", key: "percentage" },
          { title: "Categoria", key: "category" },
          { title: "Quantidade", key: "quantity" },
          { title: "P/M", key: "average_price" },
          // { title: "Total atual", key: "current_market_price" },
          // { title: "Investimento", key: "invested_amount" },
          { key: "final", classNames: "flex justify-between" },
        ]}
        dataSource={tableData || []}
        loading={loading}
        pagination={{
          page: data?.page || 1,
          pageSize: data?.page_size || 25,
          total: data?.total || 0,
          onChange: (page, page_size) =>
            setFilter((prev) => ({ ...prev, page, page_size })),
        }}
      />
    </Container>
  );
};

export default Stocks;
