import { addMinutes, format } from "date-fns";
import { ptBR } from "date-fns/locale";

type Locale = "pt-BR" | "en-US";

export function formatToMoney(
  amount?: string | number,
  locale: Locale = "pt-BR",
) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "BRL",
  }).format(Number(amount || 0));
}

export function formatToNumber(
  amount?: string | number,
  locale: Locale = "pt-BR",
) {
  return new Intl.NumberFormat(locale).format(Number(amount || 0));
}
export function formatToPercentage(
  amount?: string | number,
  locale: Locale = "pt-BR",
) {
  return (
    new Intl.NumberFormat(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(amount || 0)) + "%"
  );
}

type DateType = "date" | "datetime" | "datetimes" | "month_year";

export function formatToDate(date: string | Date, type: DateType) {
  let format_str: string;

  if (type === "date") format_str = "dd/MM/yyyy";
  else if (type === "datetime") format_str = "dd/MM/yyyy HH:mm";
  else if (type === "datetimes") format_str = "dd/MM/yyyy HH:mm:ss";
  else if (type === "month_year") format_str = "LLLL 'de' yyyy";
  else return "";

  const new_date = typeof date === "string" ? new Date(date) : date;

  return format(
    addMinutes(new_date, new Date().getTimezoneOffset()),
    format_str,
    { locale: ptBR },
  );
}
