import React from "react";
import { Trans, useTranslation } from "react-i18next";

type TProps = {
  path: string;
  data?: { [key: string]: string | number };
  component?: { [key: string]: JSX.Element };
};

const Translate: React.FC<TProps> = ({ path, data, component }) => {
  const { t } = useTranslation();

  return data ? (
    <Trans
      i18nKey={path} // optional -> fallbacks to defaults if not provided
      // defaults="hello <italic>beautiful</italic> <bold>{{what}}</bold>" // optional defaultValue
      values={data}
      components={component}
    />
  ) : (
    t(path, data)
  );
};

export default Translate;
