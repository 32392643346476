import {
  AdjustmentsIcon,
  CurrencyDollarIcon,
  TableIcon,
} from "@heroicons/react/outline";
import {
  Button,
  DatePicker,
  Metric,
  NumberInput,
  Select,
  SelectItem,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from "@tremor/react";
import Dialog from "components/Dialog";
import { brokers } from "mocks/brokers";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import service, { CreateUserMovementReq } from "services/service";
import { formatToMoney } from "utils/formatters";
import { useForm, Controller } from "react-hook-form";
import ImportXls from "./ImportXls";

type TProps = {
  refetch: () => void;
};

const MovementNew: React.FC<TProps> = ({ refetch }: TProps) => {
  const { control, register, handleSubmit, setValue, reset, watch } = useForm();

  const [loading, setLoading] = useState(false);
  const [newDialogOpen, setNewDialogOpen] = useState(false);

  const quantity = watch("quantity");
  const amount = watch("amount");

  const { data: stocks, isLoading } = useQuery({
    queryKey: ["stock-general"],
    queryFn: () => service.stockList(1, 100),
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false,
  });

  const totalAmount = useMemo(() => {
    return (quantity || 0) * (amount || 0);
  }, [quantity, amount]);

  async function onSubmitCreateManually(data: CreateUserMovementReq) {
    setLoading(true);

    try {
      const response = await service.createUserMovements(data);

      if (response.success) {
        setNewDialogOpen(false);
        refetch();
        reset();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Button
        key={1}
        size="xs"
        onClick={() => setNewDialogOpen(true)}
        loading={isLoading}
      >
        Novo
      </Button>

      <Dialog
        title="Nova Movimentação"
        open={newDialogOpen}
        handleClose={() => setNewDialogOpen(false)}
      >
        <TabGroup>
          <TabList variant="solid" className="mb-3">
            <Tab icon={AdjustmentsIcon}>Manual</Tab>
            <Tab icon={TableIcon}>Importação XLS</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <form
                onSubmit={handleSubmit((data) =>
                  onSubmitCreateManually([
                    {
                      broker: data.broker,
                      date: data.date,
                      id_stock: data.id_stock,
                      amount: Number(data.amount),
                      quantity: Number(data.quantity),
                      type: data.type,
                    },
                  ]),
                )}
              >
                <label className="mb-2 flex font-medium">Corretora</label>
                <Controller
                  name="broker"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select placeholder="Corretora" {...field}>
                      {brokers?.map((item) => (
                        <SelectItem value={item.id} key={item.id}>
                          {item.name}
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                />

                <label className="mb-2 mt-4 flex font-medium">Data</label>
                <DatePicker
                  placeholder="Data"
                  onValueChange={(data) =>
                    setValue("date", data?.toISOString())
                  }
                />

                <label className="mb-2 mt-4 flex font-medium">Ativo</label>
                <Controller
                  name="id_stock"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      placeholder="Selecione o ativo movimentado"
                      {...field}
                    >
                      {stocks?.data?.map((item: any) => (
                        <SelectItem value={item.id_stock} key={item.id_stock}>
                          {item.symbol}{" "}
                          <span className="ml-1 text-slate-400">
                            {item.type}
                          </span>
                        </SelectItem>
                      ))}
                    </Select>
                  )}
                />

                <label className="mb-2 mt-4 flex font-medium">Operação</label>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select placeholder="Qual foi a operação?" {...field}>
                      <SelectItem value="buy">Compra</SelectItem>
                      <SelectItem value="sell">Venda</SelectItem>
                      <SelectItem value="dividend">Dividendos</SelectItem>
                    </Select>
                  )}
                />

                <label className="mb-2 mt-4 flex font-medium">Quantidade</label>
                <NumberInput
                  min={1}
                  placeholder="E a quantidade?"
                  {...register("quantity", { required: true })}
                />

                <label className="mb-2 mt-4 flex font-medium">Valor</label>
                <NumberInput
                  icon={CurrencyDollarIcon}
                  enableStepper={false}
                  step="0.01"
                  placeholder="Valor unitário"
                  {...register("amount", { required: true })}
                />

                <label className="mb-2 mt-4 flex font-medium">
                  Valor total
                </label>
                <Metric>{formatToMoney(totalAmount)}</Metric>

                <div className="mt-4 flex justify-end">
                  <Button size="xs" type="submit" loading={loading}>
                    Adicionar
                  </Button>
                </div>
              </form>
            </TabPanel>
            <TabPanel>
              <ImportXls onSubmitCreateManually={onSubmitCreateManually} />
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </Dialog>
    </>
  );
};

export default MovementNew;
