import React from "react";
import Router from "./routes";
import AuthProvider from "context/auth";
import { QueryClient, QueryClientProvider } from "react-query";
import UserProvider from "context/user";
import { ToastContainer } from "react-toastify";

const queryClient = new QueryClient();

const App: React.FC = () => {
  return (
    <AuthProvider>
      <UserProvider>
        <QueryClientProvider client={queryClient}>
          <Router />
          <ToastContainer position="bottom-center" />
          {/* <ToastContainer position="top-center" className="mt-10" /> */}
        </QueryClientProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default App;
