import { useEffect, useState } from "react";
import qs from "query-string";
import { useHistory } from "react-router-dom";

type TQueryContext = {
  query: TQuery;
  set(name: string, val: string): void;
  remove(name: string): void;
};
type TQuery = {
  [k: string]: string;
};

export const useQuery = (): TQueryContext => {
  const history = useHistory();
  const [query, setQuery] = useState<TQuery>(
    () => qs.parse(location.search) as TQuery,
  );

  useEffect(() => {
    const data = qs.parse(location.search) as TQuery;
    setQuery(data);
  }, [location.search]);

  function set(name: string, val: string): void {
    const data = query;
    data[name] = val;

    const stringified = qs.stringify(query);
    history.push(`${location.pathname}${stringified ? `?${stringified}` : ""}`);
  }
  function remove(name: string): void {
    console.log("remove", name);
    setQuery((prev) => {
      delete prev[name];
      return prev;
    });
  }

  return { query: query, set, remove };
};
