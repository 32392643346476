import api from "./api";
import { Movement } from "./entities";

class Service {
  async stockList(page = 1, page_size: 100) {
    const { data } = await api.get("/stock", {
      params: { page, page_size },
    });

    return data;
  }

  async userDashboard(): Promise<UserDashboardResp> {
    const { data: response } = await api.get("/user/dashboard");

    return response;
  }

  async createUserMovements(data: CreateUserMovementReq) {
    const { data: response } = await api.post("/user/movement", data);

    return response;
  }

  async processFileXls(data: ProcessFileXlsReq): Promise<ProcessFileXlsResp> {
    const formData = new FormData();

    formData.append("document", data.document);
    formData.append("file_type", data.file_type);

    const { data: response } = await api.post(
      "/user/movement/process/file",
      formData,
    );

    return response;
  }

  async userStockInfo(id_user_stock: string): Promise<TUserStockInfo> {
    const { data: response } = await api.get(`/user/stock/${id_user_stock}`);

    return response;
  }
}

export default new Service();

export type UserDashboardResp = {
  summary: {
    total_amount: number;
    total_amount_prev: number;
    // stock_amount: number;
    // stock_amount_prev: number;
    dividends_amount: number;
    dividends_amount_prev: number;
    history: {
      id_user_profitability_history: string;
      id_user: string;
      type: string;
      amount: number;
      date: string;
    }[];
  };
};

export type ProcessFileXlsReq = {
  document: File;
  file_type: string;
};

export type CreateUserMovementReq = {
  id_stock: string;
  broker: string;
  type: string;
  quantity: number;
  amount: number;
  date: string;
  origin?: string;
}[];

export type ProcessFileXlsResp = {
  data: {
    id_stock: string;
    symbol: string;
    broker: string;
    type: string;
    quantity: number;
    amount: number;
    origin: string;
    date: string;
  }[];
  success: boolean;
};

export type TUserStockInfo = {
  info: {
    id_user_stock: string;
    id_user: string;
    id_stock: string;
    quantity: number;
    invested_amount: number;
    average_price: number;
    created_at: string;
    updated_at?: string;
    deleted_at?: string;
  };
  stock: {
    id_stock: string;
    short_name: string;
    long_name: string;
    symbol: string;
    logo_url: string;
    type: string;
    category: string;
    current_market_price: number;
    created_at: string;
    updated_at?: string;
  };
  movements: Movement[];
  dividends: Movement[];
};
