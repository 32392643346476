import {
  Button,
  Divider,
  List,
  ListItem,
  Select,
  SelectItem,
  Subtitle,
  Title,
} from "@tremor/react";
import React, { useState } from "react";
import service, {
  CreateUserMovementReq,
  ProcessFileXlsResp,
} from "services/service";
import { formatToDate, formatToMoney, formatToNumber } from "utils/formatters";
import { filesize } from "filesize";
import { toast } from "react-toastify";
import Translate from "components/Translate";

type TProps = {
  onSubmitCreateManually: (data: CreateUserMovementReq) => void;
};

const ImportXls: React.FC<TProps> = ({ onSubmitCreateManually }) => {
  const [fileSelected, setFileSelected] = useState<File>();
  const [fileType, setFileType] = useState<string>();
  const [fileData, setFileData] = useState<ProcessFileXlsResp>();
  const [loading, setLoading] = useState(false);

  async function onSubmitProcessFile() {
    console.log("onSubmitProcessFile");

    if (!fileSelected) {
      toast.warn("É necessário selecionar um arquivo primeiro");
      return;
    }
    if (!fileType) {
      toast.warn("É necessário selecionar o tipo do arquivo");
      return;
    }

    setLoading(true);

    try {
      const response = await service.processFileXls({
        document: fileSelected,
        file_type: fileType,
      });

      if (response.success) {
        setFileData(response);
      } else {
        toast.error(
          "Tivemos um problema no processamento do arquivo, tente novamente",
        );
      }
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  function handleFileSelected(e: any) {
    const files = (e.target as HTMLInputElement)?.files;

    if (files?.length) {
      setFileSelected(files[0]);
    }
  }

  function handleSubmitFileData() {
    if (!fileData) {
      toast.warn("É necessário selecionar um arquivo primeiro");
      return;
    }

    return onSubmitCreateManually(
      fileData.data.map((item) => ({
        amount: item.amount,
        broker: item.broker,
        date: item.date,
        id_stock: item.id_stock,
        quantity: item.quantity,
        type: item.type,
        origin: item.origin,
      })),
    );
  }

  function handleRemoveItemFile(index: number) {
    setFileData((prev) => {
      if (!prev) return;

      return {
        ...prev,
        data: prev?.data.filter((_, i) => i != index),
      };
    });
  }

  const file_types = [
    {
      id: "internal",
      name: "Finance Wave",
    },
    {
      id: "b3_negotiation",
      name: "B3 | Extrato > Negociação",
    },
    {
      id: "b3_movimentation",
      name: "B3 | Proventos > Recebidos",
    },
  ];

  return (
    <>
      <label className="mb-2 flex font-medium">Tipo</label>
      <Select
        placeholder="Tipo de arquivo"
        onChange={(i) => setFileType(i as unknown as string)}
      >
        {file_types?.map((item) => (
          <SelectItem value={item.id} key={item.id}>
            {item.name}
          </SelectItem>
        ))}
      </Select>
      <label className="mb-2 mt-4 flex font-medium">Arquivo</label>
      <label
        className="block w-full cursor-pointer rounded border border-dashed bg-white p-3 text-center"
        htmlFor="input-file"
      >
        Clique para selecionar um arquivo...
      </label>
      <input
        type="file"
        id="input-file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        title=""
        placeholder="Selecione o arquivo"
        onChange={handleFileSelected}
        hidden
      />

      {fileSelected && (
        <div className="mt-4">
          <strong>Nome:</strong> {fileSelected.name}
          <br />
          <strong>Tamanho:</strong> {filesize(fileSelected.size)}
        </div>
      )}

      <div className="mt-4 flex justify-end">
        <Button
          size="xs"
          type="submit"
          disabled={!fileSelected || !fileType}
          loading={loading}
          onClick={() => onSubmitProcessFile()}
        >
          {loading ? "Processando, aguarde..." : "Processar"}
        </Button>
      </div>

      <Divider />

      {fileData?.data && (
        <>
          <Title>Dados do arquivo</Title>
          <Subtitle>
            Remova as movimentações que não deseja inserir antes de
            adiciona-las!
          </Subtitle>

          <br />

          <List>
            {fileData.data.map((item, i) => (
              <ListItem key={String(i)}>
                <span>
                  <span className="font-medium text-black">{item.symbol}</span>{" "}
                  {item.broker}
                  <br />
                  {formatToDate(item.date, "date")}
                </span>
                <span>
                  <Translate path={`global.${item.type}`} /> -{" "}
                  {formatToNumber(item.quantity)} x{" "}
                  <span className="text-black">
                    {formatToMoney(item.amount)}
                  </span>
                </span>
                <Button
                  color="red"
                  className="rounded"
                  size="xs"
                  variant="light"
                  onClick={() => handleRemoveItemFile(i)}
                >
                  X
                </Button>
              </ListItem>
            ))}
          </List>

          <div className="mt-4 flex justify-end">
            <Button
              size="xs"
              type="submit"
              disabled={!fileSelected}
              loading={loading}
              onClick={() => handleSubmitFileData()}
            >
              Adicionar
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default ImportXls;
