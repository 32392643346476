// import { useAuth } from "context/auth";
import React, { Fragment, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  HomeIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
  ChartBarIcon,
  TrendingUpIcon,
  DocumentReportIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/outline";
import { useUser } from "context/user";
import { Badge } from "@tremor/react";
import { Menu, Transition } from "@headlessui/react";
import { useAuth } from "context/auth";
import { LogoFull } from "components/images/Logo/Full";
import { LogoSmall } from "components/images/Logo/Small";
import { bindClassNames } from "utils/tailwind";
import { useQuery } from "context/query";
import Translate from "components/Translate";

type IProps = {
  children: React.ReactNode;
};

const LayoutMainNew: React.FC<IProps> = ({ children }: IProps) => {
  const { setToken } = useAuth();
  const { user } = useUser();
  const { query, set } = useQuery();

  const open = useMemo(() => {
    if (!query["mn"]) {
      set("mn", "1");
      return;
    }

    return query["mn"] === "1";
  }, [query]);

  function handleToggleMenu() {
    if (open) {
      set("mn", "0");
    } else {
      set("mn", "1");
    }
  }

  const location = useLocation();

  const navigation = useMemo(() => {
    return [
      {
        name: <Translate path={`global.dashboard`} />,
        href: "/dashboard",
        icon: HomeIcon,
        current: false,
        disabled: false,
        soon: false,
      },
      {
        name: <Translate path={`global.stock`} />,
        href: "/stocks",
        icon: DocumentReportIcon,
        current: false,
        disabled: false,
        soon: false,
      },
      {
        name: <Translate path={`global.movements`} />,
        href: "/statements",
        icon: TrendingUpIcon,
        current: false,
        disabled: false,
        soon: false,
      },
      {
        name: <Translate path={`global.dividends`} />,
        href: "/dividends",
        icon: CurrencyDollarIcon,
        current: false,
        disabled: true,
        soon: false,
      },
      {
        name: <Translate path={`global.reports`} />,
        href: "/",
        icon: ChartBarIcon,
        current: false,
        disabled: true,
        soon: true,
      },
    ].map((item) => ({ ...item, current: location.pathname === item.href }));
  }, [location.pathname]);
  const userNavigation = [
    { name: <Translate path={`global.profile`} />, href: "#" },
    {
      name: <Translate path={`global.logout`} />,
      handler: () => {
        setToken(null);
      },
    },
  ];

  const menu = useMemo(() => {
    return navigation.map((item) => {
      let style_classes = "text-text bg-white hover:bg-menu-selected";

      if (item.current) style_classes = "bg-primary text-white";
      if (item.disabled)
        style_classes = "text-text bg-white opacity-25 pointer-events-none";

      return (
        <li key={item.href}>
          <Link
            className={bindClassNames(
              style_classes,
              "mb-1.5 flex h-9 w-full items-center rounded p-2 px-3 font-medium ",
            )}
            to={item.href}
          >
            <item.icon
              className={bindClassNames(
                item.current ? "text-white" : "text-menu-icon",
                "w-5",
              )}
            />
            <span className={bindClassNames(open ? "" : "sr-only", "ml-2.5")}>
              {item.name}
            </span>
            {item.soon && (
              <Badge
                size="xs"
                color="gray"
                className={bindClassNames(open ? "" : "sr-only", "ml-1")}
              >
                Soon
              </Badge>
            )}
          </Link>
        </li>
      );
    });
  }, [navigation, open]);

  return (
    <>
      <div className="flex min-h-full text-text">
        <nav
          className={bindClassNames(
            open ? "min-w-[15rem]" : "min-w-[5rem]",
            "fixed z-50 flex h-screen flex-col justify-between border-r bg-white px-6 py-4",
          )}
        >
          <div>
            {open ? (
              <LogoFull className="h-11" />
            ) : (
              <LogoSmall className="h-11" />
            )}
            <menu className="mt-10 w-full">
              <ul>{menu}</ul>
            </menu>
          </div>
          <div>
            <button
              className="flex w-full items-center justify-center rounded border border-dashed p-2"
              onClick={() => handleToggleMenu()}
            >
              {open ? (
                <ChevronLeftIcon className="w-5" />
              ) : (
                <ChevronRightIcon className="w-5" />
              )}
            </button>
          </div>
        </nav>
        <div
          className={bindClassNames(
            open ? "ml-[15rem]" : "ml-[5rem]",
            "flex w-full flex-col",
          )}
        >
          <header className="flex h-14 w-full items-center justify-end border-b px-8">
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="relative flex max-w-xs items-center">
                  <div className="flex items-center gap-3.5">
                    <img
                      className="w-8 rounded-full"
                      src="https://randomuser.me/api/portraits/men/32.jpg"
                      alt=""
                    />
                    <span className="text-text">{user?.name}</span>
                    <ChevronDownIcon className="w-3" />
                  </div>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {userNavigation.map((item, i) => (
                    <Menu.Item key={i}>
                      {({ active }) =>
                        item.href ? (
                          <a
                            href={item.href}
                            className={bindClassNames(
                              active ? "bg-gray-100" : "",
                              "block px-4 py-2 text-sm text-gray-700",
                            )}
                          >
                            {item.name}
                          </a>
                        ) : (
                          <button
                            className={bindClassNames(
                              active ? "bg-gray-100" : "",
                              "block w-full px-4 py-2 text-left text-sm text-gray-700",
                            )}
                            onClick={item.handler}
                          >
                            {item.name}
                          </button>
                        )
                      }
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </header>
          <main className="h-full w-full px-8 py-10">{children}</main>
        </div>
      </div>
    </>
  );
};

export default LayoutMainNew;
