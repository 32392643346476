import { ChevronRightIcon } from "@heroicons/react/outline";
import Skeleton from "react-loading-skeleton";
import React from "react";

// import { Container } from './styles';
type IProps = {
  title: string | React.ReactNode;
  subtitle?: string;
  breadcrumb?: (string | React.ReactNode)[];
  children: React.ReactNode;
  extra?: React.ReactNode[];
  loading?: boolean;
};

const Container: React.FC<IProps> = ({
  children,
  title,
  breadcrumb,
  subtitle,
  extra,
  loading,
}: IProps) => {
  return (
    <>
      <div className="mb-12 flex w-full flex-row items-center justify-between">
        <div className="">
          {breadcrumb && (
            <div className="flex gap-9 text-subtitle">
              {breadcrumb.map((item, i) => {
                return (
                  <>
                    {loading ? <Skeleton width={60} /> : item}
                    {i != breadcrumb.length - 1 && (
                      <ChevronRightIcon className="w-4 text-border" />
                    )}
                  </>
                );
              })}
            </div>
          )}
          <h1 className="text-2xl font-semibold">
            {loading ? <Skeleton width={120} /> : title}
          </h1>
          {subtitle && loading ? (
            <Skeleton width={240} />
          ) : (
            <sub className="text-base text-subtitle">{subtitle}</sub>
          )}
        </div>
        <div>{extra && extra.map((item) => item)}</div>
      </div>
      <div>{children}</div>
    </>
  );
};

export default Container;
