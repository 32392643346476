import React, { useMemo } from "react";
import CountUp from "react-countup";
import { getBadge } from "utils/badge";
import { formatToMoney } from "utils/formatters";
import Skeleton from "react-loading-skeleton";

type TProps = {
  title: string;
  amount: number;
  prev_amount?: number;
  loading?: boolean;
};

const DashboardMetrics: React.FC<TProps> = ({
  title,
  amount,
  prev_amount,
  loading,
}: TProps) => {
  const final = useMemo(() => {
    if (prev_amount !== undefined) {
      return getBadge(amount, prev_amount);
    }
    return null;
  }, [prev_amount]);

  return (
    <div className="flex w-full items-center justify-between">
      <div>
        <div className="text-sm font-medium text-subtitle">{title}</div>
        <div>
          <span className="mr-2 text-3xl font-semibold text-text">
            {loading ? (
              <Skeleton width={240} />
            ) : (
              <CountUp
                end={amount}
                formattingFn={formatToMoney}
                decimals={2}
                duration={0.5}
              />
            )}
          </span>
          {prev_amount !== undefined && (
            <span className="text-subtitle">
              de {formatToMoney(prev_amount)}
            </span>
          )}
        </div>
      </div>
      <div>{final}</div>
    </div>
  );
};

export default DashboardMetrics;
