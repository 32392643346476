import Table from "components/Table";
import Container from "components/layout/Container";
import React, { useEffect, useMemo, useState } from "react";
import api from "services/api";
import { formatToDate, formatToMoney, formatToNumber } from "utils/formatters";
import MovementNew from "./New";
import Translate from "components/Translate";
// import Filters from "components/Filters";

type MovementsResponse = {
  data: {
    id_user_movement: string;
    id_user: string;
    id_stock?: string;
    asset: string;
    broker: string;
    type: "buy" | "sell" | "dividend";
    quantity: number;
    amount: number;
    date: string;
    created_at: string;
    symbol?: string;
    stock_type?: string;
  }[];
  page: number;
  page_size: number;
  total: number;
};

const Movements: React.FC = () => {
  const default_filter = {
    page: 1,
    page_size: 20,
  };

  const [filter, setFilter] = useState(default_filter);
  const [data, setData] = useState<MovementsResponse>();
  const [loading, setLoading] = useState<boolean>();

  useEffect(() => {
    fetchUserMovements();
  }, [filter]);

  async function fetchUserMovements() {
    setLoading(true);

    try {
      const { data: response } = await api.get("/user/movement", {
        params: filter,
      });

      setData(response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }

  const tableData = useMemo(() => {
    return data?.data?.map((item) => ({
      broker: item.broker,
      asset: item.symbol ? (
        <>
          {item.symbol}{" "}
          <span className="text-slate-400">/ {item.stock_type}</span>
        </>
      ) : (
        item.asset
      ),
      type: <Translate path={`global.${item.type}`} />,
      quantity: formatToNumber(item.quantity),
      amount: formatToMoney(item.amount),
      date: formatToDate(item.date, "date"),
      total: formatToMoney(item.quantity * item.amount),
    }));
  }, [data]);

  return (
    <Container
      title="Minhas Movimentações"
      extra={[<MovementNew key={1} refetch={() => fetchUserMovements()} />]}
    >
      {/* <Filters
        filter={[
          {
            name: "broker",
            title: "Corretora",
            type: "string",
          },
          {
            name: "quantity",
            title: "Quantidade",
            type: "number",
          },
          {
            name: "type",
            title: "Operação",
            type: "options",
            values: Object.entries(dictType).map(([value, title]) => ({
              title,
              value,
            })),
          },
        ]}
      /> */}
      <Table
        columns={[
          { title: "Corretora", key: "broker" },
          { title: "Ativo", key: "asset", classNames: "text-right" },
          { title: "Operação", key: "type", classNames: "text-right" },
          { title: "Quantidade", key: "quantity", classNames: "text-right" },
          { title: "Valor", key: "amount", classNames: "text-right" },
          { title: "Total", key: "total", classNames: "text-right" },
          { key: "date", classNames: "text-right" },
        ]}
        dataSource={tableData || []}
        loading={loading}
        pagination={{
          page: data?.page || 1,
          pageSize: data?.page_size || 25,
          total: data?.total || 0,
          onChange: (page, page_size) =>
            setFilter((prev) => ({ ...prev, page, page_size })),
        }}
      />
    </Container>
  );
};

export default Movements;
